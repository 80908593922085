import { CONTENT_TYPE_LABEL, ROUTE_PATHS } from "@constants/consts";
import getStatusLabel from "@utils/common/getStatusLabel";
import { getDateLabel } from "./dateLabel";

export interface PaginatedFetch {
  endpoint: string;
  limit: number;
  page: number;
  contentType?: string;
}

export async function paginatedFetch<T>(props: PaginatedFetch): Promise<ReturnFetchData<T>> {
  const { endpoint, limit, page, contentType } = props;
  const skip = (page - 1) * limit;
  const hasQuery = endpoint.includes("?");
  const response = await fetch(
    `${endpoint}${hasQuery ? "&" : "?"}limit=${limit}&skip=${skip}${contentType ? `&contentType=${contentType}` : ""}`
  );
  const { data, message } = await response.json();
  if (typeof data === "undefined") throw new Error(message || "Failed to retrieve collection data");

  const totalItemsSoFar = skip + data.items.length;

  return {
    items: data.items.map((item: PageLink) => ({
      ...item,
      statusLabel: getStatusLabel(item),
      category: item?.startDate ? { title: "acties" } : item.category,
    })),
    totalResults: data.total,
    nextPage: totalItemsSoFar >= data.total || data.items.length < limit ? null : page + 1,
  };
}

export async function fetchByEndpoint<T>(endpoint: string): Promise<T> {
  const response = await fetch(endpoint);
  const { data, message } = await response.json();
  if (typeof data === "undefined") throw new Error(message || `Failed to fetch from ${endpoint}`);
  return data;
}

export const fetchLinks = async (props: PaginatedFetch): Promise<ReturnFetchData<PageLink>> => {
  const data = await paginatedFetch<PageLink>(props);

  return {
    ...data,
    items: data.items.map((item) => ({
      ...item,
      dateLabel: getDateLabel(item.createdAt || "", { showDay: true, showTime: true }),
      contentTypeLabel:
        // Always set the label if it is sponsored content
        item.sponsor?.label ??
        // Otherwise set it only if we are on the "Alles" search modal tab
        (!props.contentType
          ? item.url.includes(ROUTE_PATHS.VIDEO.VIDEOS)
            ? CONTENT_TYPE_LABEL.VIDEO
            : CONTENT_TYPE_LABEL.ARTICLE
          : ""),
    })),
  };
};
