import RadioSchedule from "@audio/RadioSchedule";
import CampaignCollection from "@pageContent/CampaignCollection";
import CategoryBasedCollection from "@pageContent/CategoryBasedCollection";
import GalleryHeader from "@pageContent/GalleryHeader";
import HighlightedItem from "@pageContent/HighlightedItem";
import JustInCollection from "@pageContent/JustInCollection";
import LinkList from "@pageContent/LinkList";
import NumericCollection from "@pageContent/NumericCollection/NumericCollection";
import PaginatedOverview from "@pageContent/PaginatedOverview";
import PortraitCollection from "@pageContent/PortraitCollection";
import RichText from "@pageContent/RichText";
import SquareCollection from "@pageContent/SquareCollection";
import VideoCollection from "@pageContent/VideoCollection";
import VideoCategoryCollection from "@pageContent/VideoCollection/VideoCategoryCollection";

const DynamicContent: React.FC<DynamicContent> = (item) => {
  if (item.contentType === "richText") {
    return <RichText richText={item.content} />;
  }
  if (item.contentType === "linkList") {
    if (item.type === "gallery-header") return <GalleryHeader items={item.items || []} />;
    if (item.type === "numeric-collection") return <NumericCollection title={item.title} items={item.items || []} />;
    if (item.type === "portrait-collection") return <PortraitCollection {...item} />;
    if (item.type === "square-collection") return <SquareCollection title={item.title} items={item.items || []} />;
    if (item.type === "mini-square-collection")
      return <SquareCollection title={item.title} items={item.items || []} isMini />;

    return <LinkList {...item} />;
  }

  if (item.contentType === "audioCollection") {
    if (item.type === "radioSchedule") return <RadioSchedule {...item} />;
  }

  if (item.contentType === "paginatedCategoryCollection") {
    return (
      <PaginatedOverview category={{ articleEndpoint: item.articleEndpoint }} collectionTitle={item.title} {...item} />
    );
  }
  if (item.contentType === "categoryBasedCollection") {
    if (item.type === "just-in") return <JustInCollection {...item} />;
    if (item.type === "articles") return <CategoryBasedCollection {...item} />;
    if (item.type === "videos")
      return <VideoCategoryCollection {...item} initialData={item.initialData as unknown as Video[]} />;
    if (item.type === "campaigns") return <CampaignCollection {...item} />;
  }
  if (item.contentType === "videoCollection") return <VideoCollection {...item} />;
  if (item.contentType === "highlightedItem") return <HighlightedItem {...item} />;

  return null;
};

export default DynamicContent;
