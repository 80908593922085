import { HOME_PAGE_ID, ROUTE_PATHS } from "@constants/consts";
import { TEALIUM_PAGE_CATEGORY } from "@constants/tealium.constants";

export let mainCategorySlugs: string[] = [];

export function setMainCategorySlugs(slugs: string[]) {
  mainCategorySlugs = slugs;
}

export const isVideoPage = (currentPath: string) =>
  currentPath.includes(ROUTE_PATHS.VIDEO.VIDEOS) || currentPath.startsWith(ROUTE_PATHS.VIDEO.EPISODES);

export default function getPageCategory(asPath: string, hasVideo?: boolean, sponsored?: boolean): string | null {
  const [currentPath, searchQuery] = asPath.split("?");
  if (searchQuery?.includes(`${ROUTE_PATHS.SEARCH}=`)) {
    return TEALIUM_PAGE_CATEGORY.SEARCH;
  }

  if (currentPath === ROUTE_PATHS.HOME_PAGE) {
    return TEALIUM_PAGE_CATEGORY.HOME_PAGE;
  }

  if (currentPath.includes(ROUTE_PATHS.STATIONS)) {
    return TEALIUM_PAGE_CATEGORY.STATION_PAGE;
  }

  if (currentPath.includes(ROUTE_PATHS.POPOUT)) {
    return TEALIUM_PAGE_CATEGORY.POPOUT;
  }

  if (currentPath.includes(ROUTE_PATHS.ARTICLE)) {
    return `${sponsored ? "sponsored_" : ""}${TEALIUM_PAGE_CATEGORY.ARTICLE}${hasVideo ? "_with_video" : ""}`;
  }

  if (currentPath.includes(ROUTE_PATHS.CAMPAIGN)) {
    return TEALIUM_PAGE_CATEGORY.CAMPAIGN;
  }

  if (isVideoPage(currentPath)) {
    return TEALIUM_PAGE_CATEGORY.VIDEO;
  }

  if (
    currentPath.endsWith(ROUTE_PATHS.CATEGORY.ARTICLES) ||
    currentPath.endsWith(ROUTE_PATHS.CATEGORY.VIDEOS) ||
    mainCategorySlugs.some((category) => currentPath.startsWith(`/${category}`))
  ) {
    return TEALIUM_PAGE_CATEGORY.CATEGORY;
  }

  if (currentPath.match(ROUTE_PATHS.DYNAMIC)) {
    return TEALIUM_PAGE_CATEGORY.DYNAMIC;
  }

  return null;
}

export const getRouteSlug = (asPath: string): string | null => {
  const [currentPath] = asPath.split("?");
  if (currentPath.endsWith(ROUTE_PATHS.CATEGORY.ARTICLES) || currentPath.endsWith(ROUTE_PATHS.CATEGORY.VIDEOS))
    return null;

  if (currentPath === ROUTE_PATHS.HOME_PAGE) return HOME_PAGE_ID;

  return currentPath.split("/").pop() || null;
};
