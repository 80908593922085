import { styled } from "@mui/material";
import { InfiniteCollectionContainer } from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import * as InfiniteCollectionItemStyled from "@pageContent/common/InfiniteCollection/InfiniteCollectionItem/InfiniteCollectionItem.styled";
import InfiniteCollectionSkeleton from "@pageContent/common/Skeletons/InfiniteCollectionSkeleton";
import { colors, fonts } from "@constants/cssVariables";
import * as Styled from "../NoSearchResult/NoSearchResult.styled";

export const ResultsTitle = styled("h3")(({ theme }) => ({
  font: fonts.bodyMedium,
  color: colors.neutral90,
  alignSelf: "flex-start",

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(2),
  },
}));

export const Title = styled(Styled.Title)({
  alignSelf: "self-start",
});

export const DetailsContainer = styled(InfiniteCollectionItemStyled.TextContainer)(({ theme }) => ({
  flexDirection: "column",
  gap: 0,
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(1),
  },
}));

export const SearchContainer = styled(InfiniteCollectionContainer, {
  shouldForwardProp: (prop) => prop !== "isLoading" && prop !== "isInitialScreen",
})<{ isLoading?: boolean; isInitialScreen?: boolean }>(({ theme, isLoading, isInitialScreen }) => ({
  margin: theme.spacing(isLoading && isInitialScreen ? 2 : 16, 0),

  [theme.breakpoints.down("sm")]: {
    [`${InfiniteCollectionItemStyled.ItemContainer}`]: {
      maxHeight: 76,
    },

    [`${DetailsContainer}`]: {
      display: "block",
    },
  },

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(isLoading && isInitialScreen ? 4 : 16, 0),
  },
}));

export const ItemDate = styled("div")(({ theme }) => ({
  font: fonts.bodySmall,
  color: colors.neutral40,
  alignSelf: "flex-start",
  marginBottom: theme.spacing(4),
}));

export const InfiniteCollectionSkeletonContainer = styled(InfiniteCollectionSkeleton)(({ theme }) => ({
  margin: theme.spacing(2, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(4, 0),
  },
}));
