import Image from "next/image";
import { styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { Button } from "@common/Buttons";
import { variantColors } from "@constants/button";
import { SCALE_ANIMATION } from "@constants/consts";
import { colors, fonts, gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const SquareCollectionWrapperItems = styled("div")<{ isMini: boolean }>(({ theme, isMini }) => ({
  display: "grid",
  gridTemplateColumns: isMini ? "repeat(3, 1fr)" : "repeat(2, 1fr)",
  rowGap: isMini ? theme.spacing(2) : theme.spacing(4),
  columnGap: theme.spacing(2),

  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(4),
    gridTemplateColumns: isMini ? "repeat(4, 1fr)" : "repeat(3, 1fr)",
  },

  [theme.breakpoints.up("lg")]: {
    ...(isMini
      ? {
          gridTemplateColumns: "repeat(6, 1fr)",
          rowGap: theme.spacing(6),
        }
      : {
          gridTemplateColumns: "repeat(4, 1fr)",
        }),
  },
}));

export const SquareCollectionWrapper = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

export const SquareCollectionTitle = styled("p")(({ theme }) => ({
  font: fonts.header3,
  color: colors.neutral90,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.up("md")]: {
    marginBottom: theme.spacing(5),
  },
}));

export const StyledAspectRationContainer = styled(AspectRatioContainer)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: 6,

  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
  },

  ...SCALE_ANIMATION,

  "&:after": {
    content: '""' as string,
    position: "absolute",
    inset: 0,
    zIndex: zIndex.base,
    background: gradients.fadeBlackBottomTop,
  },
}));

export const SquareCollectionImage = styled(Image)(() => ({
  objectFit: "cover",
}));

export const CardTitleWrapper = styled("div")<{ isMini: boolean }>(({ theme, isMini }) => ({
  position: "absolute",
  zIndex: zIndex.baseControls,
  padding: 0,
  inset: isMini ? `65% 0 auto 0` : `auto ${theme.spacing(4, 3, 4)}`,

  [theme.breakpoints.up("md")]: {
    inset: !isMini && `auto ${theme.spacing(6, 4, 6)}`,
  },

  ...(isMini && {
    maxWidth: "85%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
  }),
}));

export const Title = styled("p")(() => ({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  font: fonts.header3,
  color: colors.neutral0,
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
}));

export const TitleMini = styled(Button)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  borderRadius: 50,
  height: "auto",

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(1, 2.5),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(1, 3),
  },

  "& p": {
    font: `${fonts.header6} !important`,
    padding: "1px 3px !important",
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },

  "&:hover, &:active": {
    backgroundColor: variantColors.primary.backgroundColor,
  },
}));

export const Subtitle = styled(Title)(() => ({
  font: fonts.header5,
}));
