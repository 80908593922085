import Link from "next/link";
import { VARIANT } from "@constants/button";
import trackContentClick from "@utils/common/trackContentClick";
import * as Styled from "./SquareCollection.styled";

interface SquareCollectionProps {
  title: string;
  items: Array<PageLink>;
  isMini?: boolean;
}

export const itemTestId = "square-collection-item-test-id";
export const miniItemTestId = "mini-square-collection-item-test-id";
export const itemsWrapperTestId = "square-collection-wrapper-items-test-id";

const SquareCollection: React.FC<SquareCollectionProps> = ({ title: sectionTitle, items, isMini = false }) => (
  <Styled.SquareCollectionWrapper>
    <Styled.SquareCollectionTitle>{sectionTitle}</Styled.SquareCollectionTitle>
    <Styled.SquareCollectionWrapperItems isMini={isMini} data-testid={itemsWrapperTestId}>
      {items.map((item, index) => (
        <Link key={item.id} href={item.url} target={item.target}>
          <Styled.StyledAspectRationContainer
            data-is-mini={isMini}
            data-testid={isMini ? miniItemTestId : itemTestId}
            aspectRatio="1:1"
            onClick={() =>
              trackContentClick({
                position: index + 1,
                label: sectionTitle,
                ...item,
              })
            }
          >
            <Styled.SquareCollectionImage
              alt={item.title}
              src={item.imageUrl}
              fill
              sizes="(max-width: 900px) 50vw, (max-width: 1200px) 33vw, 25vw"
            />
            <Styled.CardTitleWrapper isMini={isMini} data-is-mini={isMini}>
              {isMini ? (
                <Styled.TitleMini variant={VARIANT.PRIMARY}>{item.title}</Styled.TitleMini>
              ) : (
                <>
                  {item.subtitle && <Styled.Subtitle>{item.subtitle}</Styled.Subtitle>}
                  <Styled.Title>{item.title}</Styled.Title>
                </>
              )}
            </Styled.CardTitleWrapper>
          </Styled.StyledAspectRationContainer>
        </Link>
      ))}
    </Styled.SquareCollectionWrapperItems>
  </Styled.SquareCollectionWrapper>
);

export default SquareCollection;
