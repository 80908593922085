import { FC } from "react";
import { PrimaryButton } from "@common/Buttons";
import ArticleSkeleton from "@pageContent/common/Skeletons/ArticleSkeleton";
import ButtonSkeleton from "@pageContent/common/Skeletons/ButtonSkeleton";
import TextSkeleton from "@pageContent/common/Skeletons/TextSkeleton";
import {
  InfiniteCollectionContainer,
  ItemsContainer,
  TitleWrapper,
} from "../InfiniteCollection/InfiniteCollection.styled";

interface InfiniteCollectionSkeletonProps {
  title: string;
  limit: number;
  showCategory?: boolean;
  showAllText?: string;
  className?: string;
}

type CollectionSkeletonProps = Omit<InfiniteCollectionSkeletonProps, "showAllText" | "className">;

export const CollectionSkeleton: FC<CollectionSkeletonProps> = ({ title, limit, showCategory }) => (
  <>
    {[...Array(limit)].map((_, i) => (
      <ArticleSkeleton showCategory={showCategory} key={`${title}-collection-${i}`} />
    ))}
  </>
);

const InfiniteCollectionSkeleton: FC<InfiniteCollectionSkeletonProps> = ({
  title,
  limit,
  showCategory,
  showAllText,
  className,
}) => {
  return (
    <InfiniteCollectionContainer className={className}>
      <TitleWrapper>
        <TextSkeleton height={[24, 24, 32]} width={[180, 180, 240]} />
        {showAllText && <TextSkeleton height={16} width={[60, 60, 100]} />}
      </TitleWrapper>
      <ItemsContainer>
        <CollectionSkeleton title={title} limit={limit} showCategory={showCategory} />
      </ItemsContainer>
      <ButtonSkeleton>
        <PrimaryButton disabled fullWidth />
      </ButtonSkeleton>
    </InfiniteCollectionContainer>
  );
};

export default InfiniteCollectionSkeleton;
