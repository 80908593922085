import { isAfter, isBefore } from "date-fns";
import { ACTIVE_CAMPAIGN, EXPIRED_CAMPAIGN } from "@constants/consts";

export default function getStatusLabel(item: PageLink) {
  if (item?.startDate && item?.endDate) {
    const now = new Date();
    if (isAfter(now, new Date(item.startDate)) && isBefore(now, new Date(item.endDate))) return ACTIVE_CAMPAIGN;
    if (isAfter(now, new Date(item.endDate))) return EXPIRED_CAMPAIGN;
  }
  return item.sponsor?.label;
}
