const isCampaignActive = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) return;

  const now = new Date();

  if (new Date(startDate) <= now && now <= new Date(endDate)) {
    return "active";
  }

  return "expired";
};

export default isCampaignActive;
