import { format, isAfter, isBefore, isToday, isTomorrow } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import nl from "date-fns/locale/nl";
import { AudioType, radioProfile } from "@constants/consts";
import { Dates, TIMEZONE, TODAY, TOMORROW } from "@constants/date";
import capitalize from "@utils/common/capitalize";

export class Schedule {
  id: string;
  type: "show" = AudioType.SHOW;
  startTime: string;
  broadcastDate: Date;
  endTime: string;
  showTime: string;
  title: string;
  slug: string;
  thumbnailUrl: string = "";
  isLive: boolean;
  dayShort: string;
  day: string;
  constructor(data: UniversalApiRadioSchedule) {
    const today = utcToZonedTime(new Date(), TIMEZONE);
    const showStartDate = utcToZonedTime(new Date(data.broadcastStartDate), TIMEZONE);

    const showEndDate = utcToZonedTime(new Date(data.broadcastEndDate), TIMEZONE);

    this.id = data.id;
    this.slug = data.show.slug;
    this.title = data.show.title;
    this.startTime = format(showStartDate, "HH:mm", { locale: nl });
    this.broadcastDate = new Date(data.broadcastStartDate);
    this.endTime = format(showEndDate, "HH:mm", { locale: nl });
    this.showTime = `${this.startTime} - ${this.endTime}`;
    this.dayShort = capitalize(format(showStartDate, "EEEEEE", { locale: nl }));
    this.day = capitalize(format(showStartDate, `EEEEEE d '${Dates[showStartDate.getMonth()]}'`, { locale: nl }));
    if (isToday(showStartDate)) {
      this.day = TODAY;
    } else if (isTomorrow(showStartDate)) {
      this.day = TOMORROW;
    }
    this.isLive = isAfter(today, showStartDate) && isBefore(today, showEndDate);
    if (data.show.images.length) {
      const thumbnail = data.show.images.find((image: UniversalApiImage) => image.variant?.slug.includes(radioProfile));
      this.thumbnailUrl = thumbnail ? thumbnail.uri : data.show.images[0].uri;
    }
  }
}

export class Playout {
  type: "playout" = AudioType.PLAYOUT;
  id: string;
  track: Track;
  startTime: string;
  broadcastDate: Date;
  constructor(data: UniversalApiPlayout) {
    const playoutStartDate = utcToZonedTime(new Date(data.broadcastDate), TIMEZONE);

    this.id = data.id;
    this.track = new Track(data.track);
    this.startTime = format(playoutStartDate, "HH:mm", { locale: nl });
    this.broadcastDate = new Date(data.broadcastDate);
  }
}

export type TrackHistoryItem = Playout | Schedule;

export class Station {
  slug: string;
  type: "station" = AudioType.STATION;
  mountSource: string;
  title: string;
  secondaryTitle: string;
  thumbnailUrl: string;
  videoId?: string;
  constructor(data: UniversalApiStation) {
    this.slug = data.slug;
    this.mountSource = data.media.find((mediaItem: UniversalApiMedia) => mediaItem.source === "mount_point")?.uri || "";
    this.title = data.title;
    this.secondaryTitle = data.shortTitle;
    this.thumbnailUrl = data.images.find((image: UniversalApiImage) => image.imageType === "image")?.uri || "";
    this.videoId = data.references?.[0]?.embedId;
  }
}

export class Show implements AudioShowEntry {
  id: string;
  title: string;
  slug: string;
  type: "show" = AudioType.SHOW;
  sourceData: UniversalApiShow;
  createdAt: string;
  updatedAt: string;
  subtitle: string;
  description: string;
  url: string;
  thumbnailUrl: string;
  backgroundUrl: string;

  constructor(
    data: UniversalApiShow,
    options: {
      broadcastStartDate: string;
      broadcastEndDate: string;
      createdAt?: string;
      updatedAt?: string;
      thumbnailUrl?: string;
    }
  ) {
    const showStartDate = utcToZonedTime(new Date(options.broadcastStartDate), TIMEZONE);
    const showEndDate = utcToZonedTime(new Date(options.broadcastEndDate), TIMEZONE);
    const startTime = format(showStartDate, "HH:mm", { locale: nl });
    const endTime = format(showEndDate, "HH:mm", { locale: nl });
    this.subtitle = `${startTime} - ${endTime}`;
    this.id = data.id;
    this.title = data.title;
    this.slug = data.slug;
    if (data.images.length) {
      this.thumbnailUrl = data.images.find((image: UniversalApiImage) => image.imageType === "image")?.uri || "";
      this.backgroundUrl = data.images.find((image: UniversalApiImage) => image.imageType === "background")?.uri || "";
    }
  }
}

export class Track implements NowPlayingDetails {
  id: string;
  type: "track" = AudioType.TRACK;
  title: string;
  artistName: string;
  imageUrl: string = "";
  imageTitle: string = "";
  previewUrl?: string;
  spotifyLink?: string;
  isrc?: string;

  constructor(data: UniversalApiTrack) {
    this.id = data.id;
    this.title = data.title;
    this.artistName = data.artistName;
    if (data.images) {
      const trackImage = data.images.find((image) => image.uri);
      this.imageUrl = trackImage?.uri || "";
      this.imageTitle = trackImage?.title || "";
    }
    if (data.media) {
      const spotifyMedia = data.media.find((media) => media.source === "spotify_uri");
      const fragmentMedia = data.media.find((media) => media.source === "spotify_preview");

      if (fragmentMedia) this.previewUrl = fragmentMedia.uri + ".mp3";
      if (spotifyMedia) this.spotifyLink = `https://open.spotify.com/track/${spotifyMedia.uri.split(":")[2]}`;
    }
    this.isrc = data.isrc;
  }
}
