import React from "react";
import Tealium from "@4tn/webx-analytics";
import { PrimaryButton } from "@common/Buttons";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_NAME } from "@host/constants/tealium.constants";
import { SHOW_MORE } from "@constants/consts";
import usePaginatedFetch from "@utils/common/usePaginatedFetch";
import InfiniteCollectionSkeleton, { CollectionSkeleton } from "../Skeletons/InfiniteCollectionSkeleton";
import * as Styled from "./InfiniteCollection.styled";
import InfiniteCollectionItem from "./InfiniteCollectionItem";

interface InfiniteCollectionProps {
  title: string;
  endpoint: string;
  limit: number;
  collectionName: string;
  showCategory?: boolean;
  showAllText?: string;
  showAllUrl?: string;
  initialData?: PageLink[];
}

const InfiniteCollection: React.FC<InfiniteCollectionProps> = ({
  title,
  endpoint,
  limit,
  collectionName,
  showCategory,
  showAllText,
  showAllUrl,
  initialData,
}) => {
  const { items, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = usePaginatedFetch<PageLink>({
    collectionName,
    endpoint,
    limit,
    initialData: () => {
      if (initialData) {
        return {
          pageParams: [1],
          pages: [
            {
              items: initialData.slice(0, limit),
              nextPage: 1,
              totalResults: 0,
            },
          ],
        };
      }
      return undefined;
    },
  });

  const loadMore = () => {
    fetchNextPage();
    Tealium.link({
      event_category: TEALIUM_EVENT_CATEGORY.CONTENT_LISTING,
      event_name: TEALIUM_EVENT_NAME.CONTENT_LISTING_MORE,
      event_label: title,
    });
  };

  if (isLoading && !items?.length) {
    return (
      <InfiniteCollectionSkeleton title={title} limit={limit} showCategory={showCategory} showAllText={showAllText} />
    );
  }

  if (!items?.length) return null;

  return (
    <Styled.InfiniteCollectionContainer>
      <Styled.TitleWrapper>
        <Styled.Title>{title}</Styled.Title>
        {showAllText && showAllUrl && <Styled.ShowAllLink href={showAllUrl}>{showAllText}</Styled.ShowAllLink>}
      </Styled.TitleWrapper>
      <Styled.ItemsContainer>
        {items.map((link, index) => (
          <InfiniteCollectionItem
            key={link.id}
            position={index + 1}
            sectionTitle={title}
            link={link}
            showCategory={showCategory}
          />
        ))}

        {isFetchingNextPage && <CollectionSkeleton title={title} limit={limit} showCategory={showCategory} />}
      </Styled.ItemsContainer>
      {hasNextPage && (
        <PrimaryButton fullWidth isLoading={isFetchingNextPage} onClick={loadMore}>
          {SHOW_MORE}
        </PrimaryButton>
      )}
    </Styled.InfiniteCollectionContainer>
  );
};

export default InfiniteCollection;
