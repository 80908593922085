import Image from "next/image";
import { styled } from "@mui/material/styles";
import AspectRatioContainer from "@common/AspectRatioContainer";
import Swimlane from "@pageContent/common/Swimlane";
import { LeftArrow, RightArrow } from "@pageContent/common/Swimlane/Swimlane.styled";
import { SCALE_ANIMATION, firstChildSelector, lastChildSelector, lastDirectChildDiv } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const PortraitCollectionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

export const PortraitCollectionHeader = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "baseline",
}));

export const PortraitCollectionTitle = styled("p")(() => ({
  font: fonts.header3,
  color: colors.neutral90,
}));

export const PortraitCollectionShowAll = styled("div")(() => ({
  font: fonts.buttonMedium,
  color: colors.primary,
}));

export const Portrait = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  width: "calc((100% - 101px) / 2)",
  maxWidth: 160,

  [firstChildSelector]: {
    marginLeft: theme.spacing(4),
  },

  [lastChildSelector]: {
    "&:after": {
      content: `""`,
      position: "absolute",
      width: 16,
      height: "100%",
      top: 0,
      right: -16,
    },
  },

  [theme.breakpoints.up("md")]: {
    width: "calc((100% - 208px) / 4)",
    maxWidth: 175,
    marginRight: theme.spacing(4),

    [firstChildSelector]: {
      marginLeft: theme.spacing(20),
    },

    [lastChildSelector]: {
      "&:after": {
        width: 80,
        right: -80,
      },
    },
  },

  [theme.breakpoints.up("lg")]: {
    width: "calc((100% - 239px) / 6)",
    maxWidth: 200,
  },
}));

export const AspectRatio = styled(AspectRatioContainer)(({ theme }) => ({
  borderRadius: 8,
  [theme.breakpoints.up("md")]: {
    ...SCALE_ANIMATION,
  },
}));

export const PortraitImage = styled(Image)(() => ({
  objectFit: "cover",
  borderRadius: 8,
}));

export const StyledSwimlane = styled(Swimlane)(({ theme }) => ({
  [lastDirectChildDiv]: {
    display: "-webkit-box",
    padding: theme.spacing(4, 0),
  },

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(0, -20),

    [lastDirectChildDiv]: {
      padding: theme.spacing(6, 0),
    },
  },

  [`${LeftArrow}, ${RightArrow}`]: {
    top: "50%",
    transform: "translateY(-26px)",
    backgroundColor: colors.primary,
    color: colors.neutral0,

    "&:hover": {
      backgroundColor: colors.primary50,
      color: colors.neutral0,
    },

    "&:active": {
      backgroundColor: colors.primary120,
      color: colors.neutral0,
    },
  },
}));
