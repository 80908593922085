import React from "react";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";

const collectionName = "campaign-collection";
const limit = 6;

const CampaignCollection: React.FC<CategoryBasedCollection> = (props) => {
  return <InfiniteCollection {...props} limit={limit} collectionName={collectionName} showCategory />;
};

export default CampaignCollection;
