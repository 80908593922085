import { styled } from "@mui/material";
import { ItemsContainer } from "@pageContent/common/InfiniteCollection/InfiniteCollection.styled";
import { colors, fonts } from "@constants/cssVariables";

export const NoItemsText = styled("p")({
  font: fonts.bodyLarge,
  color: colors.neutral80,
  alignSelf: "start",
});

export const CollectionItems = styled(ItemsContainer)(({ theme }) => ({
  paddingBottom: theme.spacing(10),
}));
