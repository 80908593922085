import Link from "next/link";
import { styled } from "@mui/material";
import { ButtonContainer } from "@common/Buttons";
import { colors, fonts, grid } from "@constants/cssVariables";

export const InfiniteCollectionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(8, 0),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),

  [`${ButtonContainer}`]: {
    marginTop: theme.spacing(2),
  },

  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(8, 0),
  },

  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(10, 0),
  },
}));

export const TitleWrapper = styled("div")(({}) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "end",
}));

export const Title = styled("h3")(({}) => ({
  font: fonts.header3,
  color: colors.neutral90,
  alignSelf: "flex-start",
}));

export const ShowAllLink = styled(Link)(({ theme }) => ({
  display: "contents",
  font: fonts.buttonSmall,
  color: colors.primary,
  [theme.breakpoints.up("md")]: {
    font: fonts.buttonMedium,
  },
}));

export const ItemsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  alignSelf: "flex-start",
  width: "100%",

  [theme.breakpoints.up("sm")]: {
    display: "grid",
    gap: theme.spacing(6, 4),
    gridTemplateColumns: `repeat(${grid.articleGridColumnsMD}, minmax(0, 1fr))`,
    alignItems: "stretch",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: `repeat(${grid.articleGridColumnsLG}, minmax(0, 1fr))`,
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: `repeat(${grid.articleGridColumnsXL}, minmax(0, 1fr))`,
  },
}));
