import { DynamoDBClient, GetItemCommand, ScanCommand } from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { tableNames } from "@constants/consts";

const client = new DynamoDBClient({
  region: process.env.DYNAMO_DB_REGION,
  endpoint: process.env.DYNAMO_DB_ENDPOINT,
});

const getItemBySlug = async <T = Record<string, any>>(tableName = "", slug = ""): Promise<null | T> => {
  const command = new GetItemCommand({
    TableName: `${process.env.MFE_NAME}-${tableName}`,
    Key: {
      slug: {
        S: slug,
      },
    },
  });

  const { Item } = await client.send(command);

  return Item ? (unmarshall(Item) as T) : null;
};

const getMainCategories = async (): Promise<null | Category[]> => {
  const command = new ScanCommand({
    TableName: `${process.env.MFE_NAME}-${tableNames.category}`,
    FilterExpression: "#type = :value",
    ExpressionAttributeValues: {
      ":value": { S: "mainCategory" },
    },
    ExpressionAttributeNames: { "#type": "type", "#url": "url" },
    ProjectionExpression: "slug,title,#url",
  });

  const { Items } = await client.send(command);
  return Items ? Items.map((item) => unmarshall(item) as Category) : null;
};

const getSlugsFromItems = async (tableName = ""): Promise<string[] | null> => {
  const command = new ScanCommand({
    TableName: `${process.env.MFE_NAME}-${tableName}`,
    ProjectionExpression: "slug",
  });

  const { Items } = await client.send(command);

  return Items ? Items.map((item) => unmarshall(item).slug) : null;
};

const getFirstItem = async <T = Record<string, any>>(tableName = ""): Promise<null | T> => {
  const command = new ScanCommand({
    TableName: `${process.env.MFE_NAME}-${tableName}`,
    Limit: 1,
  });

  const { Items } = await client.send(command);

  return Items ? (Items.map((item) => unmarshall(item))?.[0] as T) : null;
};

const getAllItems = async <T = Record<string, any>>(tableName = ""): Promise<null | T[]> => {
  const fullTableName = `${process.env.MFE_NAME}-${tableName}`;

  const command = new ScanCommand({ TableName: fullTableName });

  const { Items } = await client.send(command);

  return Items ? (Items.map((item) => unmarshall(item)) as T[]) : null;
};

const DynamoDBUtils = {
  client,
  getItemBySlug,
  getSlugsFromItems,
  getMainCategories,
  getFirstItem,
  getAllItems,
};

export default DynamoDBUtils;
